import { User } from '../interfaces/User'

export const isAuthorized = (
  isSigned: boolean,
  user: User | null,
  hasPublicAccess?: boolean,
) => {
  if ((!isSigned || !user) && !hasPublicAccess) {
    return false
  }

  return true
}

export const hasPermission = (
  user: User | null,
  _location: any,
  hasPublicAccess?: boolean,
) => {
  if (hasPublicAccess) return true

  if (!user) return false

  return true
}
